<style lang="scss"></style>
<!--eslint-disable-->
<template>
  <section class="text-center">
    <h3>Assign a Device</h3>
    <h4 class="font-weight-bolder my-2">Assign a device to an owner</h4>

    <section class="my-2">
      <b-alert v-if="linkError" show variant="danger">
        <div class="alert-body">
          {{ linkError }}
        </div>
      </b-alert>

      <label>Start Typing to search for Owner</label>
      <owner-search-select
        :selected="selectedOwner"
        @change="updateSelectedOwner"
      />
    </section>

    <section class="d-inline-flex full-width mt-2">
      <div class="ui-spacer"></div>
      <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
      <b-button
        :disabled="selectedOwner == null"
        variant="success"
        @click="linkDevice()"
        >Attach</b-button
      >
    </section>
  </section>
</template>
<!--eslint-enable-->

<script>
import deviceService from "@/services/DeviceService";
import OwnerSearchSelect from "@/components/input/PaginatedSearchSelect/OwnerSearchSelect.vue";

export default {
  components: {
    OwnerSearchSelect,
  },
  props: {
    deviceUid: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      selectedOwner: null,
      linkError: "",
    };
  },
  methods: {
    async linkDevice() {
      if (this.selectedOwner) {
        try {
          await deviceService.attachOwner(this.deviceUid, this.selectedOwner.uid);

          this.$toast.error("The device has been attached", {
            toastClassName: ['toast-std', 'success-toast'],
          })

          this.$emit('refresh', true)

        } catch (err) {

          // Default error messages
          let errorText = 'Could not fetch the list of owners'

          if (res && res.data.error) {
            errorText = res.data.error
          }
          this.linkError = errorText
        }
      }
    },
    updateSelectedOwner(owner) {
      this.selectedOwner = owner;
    }
  },
};
</script>
<style lang="scss">
pre {
  text-align: left;
  white-space: pre-wrap;
  background-color: #eee;
  border: 1px solid #c0c0c0;
  padding: 20px !important;
  border-radius: 10px;
  font-family: monospace !important;
}
.left {
  text-align: left;
}
.note {
  border-left: 5px solid #ccc;
  padding: 10px;
}
</style>
